import React, { Component } from 'react';
import Page from '../components/Page';
import Container from '../components/styles/Container';
import Text from '../components/styles/Text';

class garage extends Component {
  render() {
    return (
      <div>
        <Page>
          <Container>
            <div
              dangerouslySetInnerHTML={{
                __html: `
                <p><strong>ThoughtFull Toys/Modarri Minimum Advertised Price Policy</strong></p>
                <p>ThoughtFull Toys Inc. (TTI) is the inventor and creator of a highly engineered, unique toy car products, Modarri,&amp; Enduro which<br />
                encompasses an innovative designs and patent pending features.</p>
                <p>TTI/Modarri/Enduro is building a brand image and reputation for providing high quality, high value products and service. To<br />
                enhance and preserve this image and our marketplace presence, we have unilaterally instituted a policy of minimum advertised<br />
                price (MAP) standards for our products. This Minimum Advertised Price Policy (“MAP Policy”) will become effective June 1,<br />
                2014 and will apply to distributors and retailers, including catalogs and internet retailers (collectively, “Resellers”), who resell<br />
                TTI/Modarri/Enduro products to end users.</p>
                <p><strong>AMAZON POLICY:</strong> Unless granted written and signed permission from Thoughtfull Toys, Amazon sellers are restricted from<br />
                selling NEW condition Modarri products on Amazon through FBA (Fulfilled by Amazon), or Amazon Vendor means.</p>
                <p><strong>Official Policy and Guidelines</strong></p>
                <p>1. TTI/Modarri/Enduro reserves the right, in its unilateral discretion, to take other<br />
                action with respect to any reseller that violates this MAP Policy.</p>
                <p>2. The MAP Policy will be enforced by TTI/Modarri/Enduro in its sole discretion.</p>
                <p>3. TTI/Modarri/Enduro has the right to make its own independent decision regarding<br />
                product allocations and reseller participation as an authorized account at any time. In<br />
                order to protect their brand, TTI/Modarri/Enduro reserves the right to take legal action<br />
                against resellers who break the MAP agreement, if necessary.</p>
                <p>4. TTI/Modarri/Enduro will maintain an updated MAP Products list of those products<br />
                that will fall under this MAP Policy. TTI/Modarri/Enduro reserves the right to update or<br />
                modify this list at any time.</p>
                <p>5. TTI/Modarri reserves the right to cancel all orders and indefinitely refuse to accept<br />
                any new orders from any resellers upon verification that such reseller has advertised any<br />
                MAP Products at a net retail sales price less than the current MAP Price established by<br />
                TTI/Modarri, or if the reseller has violated this policy in any other way.</p>
                <p>6. All MAP Products listed will have a MAP Price. Listing a price less than the MAP<br />
                Price next to the featured MAP Product in any advertising will be viewed as a violation of<br />
                this MAP Policy. This MAP Policy applies to all advertisement of MAP Products in any and<br />
                all media, including, without limitation, flyers, posters, coupons, mailers, inserts,<br />
                newspapers, magazines catalogs, mail order catalogs, internet or similar electronic media<br />
                including websites, forums, email newsletters, email solicitations, television, radio, and<br />
                public signage. This MAP Policy also applies to any activity that TTI/Modarri/Enduro<br />
                determines, in its sole discretion, is designed or intended to circumvent the intent of this<br />
                MAP Policy.</p>
                <p>7. It shall not be a violation of this MAP Policy to advertise in general that the reseller<br />
                has “the lowest prices” or will match or beat its competitors’ prices, or to use similar<br />
                phrases; so long as the reseller does not include any advertised price below MAP and<br />
                otherwise complies with this MAP Policy.</p>
                <p>8. It shall not be a violation of this MAP Policy to advertise that a customer may ”call<br />
                for price” or “email for price”, or to use similar language, specifically with respect to<br />
                TTI/Modarri/Enduro products, so long as no price is listed.</p>
                <p>9. TTI/Modarri/Enduro sales representatives are NOT permitted to discuss this Policy<br />
                or make any agreements or assurances with respect to TTI/Modarri/Enduro Policy<br />
                regarding reseller advertising or pricing.</p>
                <p><strong>Modarri MAP Products List</strong></p>
                <p><em><strong>Part # Product MAP Price</strong></em></p>
                <p>1101-01 0863952011018 Modarri 3 Pack $49.99<br />
                1102-01 0863952011025 Modarri Delux 3 Pack $59.99<br />
                1103-01 0863952011032 Modarri Transporter $79.99<br />
                1104-01 0863952011049 Modarri Collector Case $19.99<br />
                1105-01 0863952011056 Modarri Party Pack $219.99<br />
                1106-01 0857086007085 Modarri Super Delux Rally Pack $39.99<br />
                1107-01 0857086007092 Modarri Delux 2 Car Rescue Pack $49.99<br />
                1110-01 0863952011100 Modarri S1 Street Car Single $19.99<br />
                1111-01 0863952011117 Modarri S1 Beach Cruzer Single $19.99<br />
                1112-01 0863952011124 Modarri S1 Stinger Delux Single $24.99<br />
                1113-01 0857086007139 Modarri S1 Super Charger with Cones $19.99<br />
                1122-01 0863952011223 Modarri S2 Muscle Car Delux Single $24.99<br />
                1123-01 0857086007153 Modarri S2 Inferno Car with Barriers $19.99<br />
                1130-01 0863952011308 Modarri X1 Dirt Car Single $19.99<br />
                1131-01 0863952011315 Modarri S1 Camo Car Single $19.99<br />
                1132-01 0863952011322 Modarri X1 Dirt Delux Single $24.99<br />
                1133-01 0857086007122 Modarri X1 Midnight Camo with Jump $19.99<br />
                1134-01 0857086007184 Modarri X1 Desert Camo with Jump $19.99<br />
                1140-01 0859110006354 Modarri R1 Roadster $24.99<br />
                1150-01 0863952011506 Modarri T1 Track Car Single $19.99<br />
                1152-01 0863952011520 Modarri T1 Track Single Delux $24.99<br />
                1153-01 0857086007146 Modarri T1 Turbo Track Car with Tire Stacks $19.99<br />
                1160-01 0859110006361 Modarri C1 Concept Car Delux Single $24.99<br />
                1170-01 0863952011704 Modarri DIY1 Car Single $19.99<br />
                1171-01 0863952011711 Modarri S1 Paint-It&#x2122; Muscle Car Delux $24.99<br />
                1172-01 0857086007108 Modarri S2 Paint-It Auto Design Studio $29.99<br />
                1173-01 0857086007177 Modarri S2 Paint-It Auto Design Studio Party $19.99<br />
                1301-01 0863952013012 Modarri S1 Chrome Body Pack $ 9.99<br />
                1302-01 0863952013029 Modarri T1 Le Mans Body Pack $ 9.99<br />
                1303-01 0863952013036 Modarri X1 Fire Body Pack $ 9.99<br />
                1311-01 0857086007115 Modarri Accessory Drive Pack $14.99<br />
                1312-01 0857086007160 Modarri Delux Pack Street Track $69.99<br />
                1392-01 0863952013920 Modarri Performance Wheel Pack Assortment $ 5.99<br />
                2010-01 0863952020102 Enduro Truck $49.99<br />
                2020-01 0863952020201 Enduro Racer $24.99</p>
                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>`,
              }}
            />
          </Container>
        </Page>
      </div>
    );
  }
}

export default garage;
